import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"


import SelfPortrait from '../components/images/SelfPortrait'
import LocationLogoSVG from '../components/images/LocationLogoSVG'


const Me = () => {
  
    return (
        <Layout>
            <SEO title="About Me" />
        <div className="flex flex-col justify-center md:flex-row md:mr-4 lg:mx-24 lg:mt-24 xl:mx-40">
            <div className="flex-grow w-4/5 md:w-3/6 xl:w-2/6 mx-auto mt-6">
                <SelfPortrait />
                <p className="mt-6 lg:mt-12 text-center">
                    <LocationLogoSVG style={{ display: 'inline' }} />{" "} Hong Kong
                </p>
            </div>
            <div className="py-4 md:ml-16">
                <h1 className="my-2 font-bold"><span style={highlightText}>About Me</span></h1>
                <p className="mb-3">
                    Hello, my name is <span style={highlightText}>Betty</span>. 
                    I am a private wealth professional
                    turned full-stack software engineer with a strong
                    interest in human-centred design and user experience.
                </p>
                <p className="mb-3">
                    As a history of art graduate of the University of Warwick,
                    I have a deep sense of appreciation and a keen eye for Art
                    and Design.
                </p>
                <p className="mb-3">
                    Learning to code has enabled me to leverage my creativity
                    as well as intellectual curiosity, which I believe are keys
                    to solving problems and making people's lives better.
                </p>
                <p className="mb-3">
                    I am also passionate about bridging the diversity gap in tech.
                    As a member of several women in tech groups online, I
                    strive to make contribution whenever I can.
                </p>
            
                <h1 className="mt-6 mb-2 font-bold"><span style={highlightText}>Tech Stack</span></h1>
                <div>
                    <h1><span className="font-bold">Primary Language:</span>{" "}JavaScript</h1>
                    <div className="flex mt-2 mb-6">
                        <ul style={{flex:'1 0 40%'}}>
                            <li className="font-bold">Front End</li>
                            <li>React</li>
                            <li>Redux</li>
                            <li>Gatsby.js</li>
                            <li>jQuery</li>
                            <li>HTML</li>
                            <li>CSS, SASS</li>
                            <li>Tailwind CSS</li>
                            <li>EJS, Handlebars</li>
                        </ul>
                        <ul style={{flex:'1 0 40%'}}>
                            <li className="font-bold">Database</li>
                            <li>PostgresQL (SQL)</li>
                            <li>Knex.js</li>
                            <li>MongoDB (NoSQL)</li>
                            <li>Mongoose</li>
                            <li>GraphQL</li>
                            <li>Redis</li>
                            <li>Google Firebase</li>
                        </ul>
                    </div>

                    <div className="flex my-6">
                        <ul style={{flex:'1 0 40%'}}>
                            <li className="font-bold">Back End</li>
                            <li>Node.js</li>
                            <li>Express.js</li>
                            <li className="font-bold mt-6">Testing</li>
                            <li>Jest</li>
                        </ul>
                        <ul style={{flex:'1 0 40%'}}>
                            <li className="font-bold">Others</li>
                            <li>AWS</li>
                            <li>Docker</li>
                            <li>Git, Github</li>
                            <li>Socket.io</li>
                            <li>REST API</li>
                            <li>JWT, OAuth</li>
                            <li>CircleCI</li>
                        </ul>
                    </div>
                </div>

                <h1 className="mt-6 mb-2 font-bold"><span style={highlightText}>What I am learning / working on</span></h1>
                <div className="flex mt-2 mb-6">
                    <ul>
                        <li>React Native</li>
                        <li>JavaScript Algorithms & Data Structures</li>
                        <li>TypeScript</li>
                        <li>Python</li>
                        <li>Wordpress, PHP</li>
                        <li>AWS Certified Developer (Associate)</li>

                    </ul>
                </div>

                <h1 className="mt-6 mb-2 font-bold"><span style={highlightText}>What I am planning to learn / work on </span></h1>
                <div className="flex mt-2 mb-6">
                    <ul>
                        <li>Next.js</li>
                        <li>Golang</li>
                        <li>C# & Unity</li>
                        <li>Digital Marketing</li>
                        <li>Blender & 3D Modelling</li>
                        <li>3D Printing</li>
                        <li>IoT</li>
                        <li>UX & Human-centred Design</li>
                        <li>Ruby on Rails</li>

                    </ul>
                </div>
            </div>
        </div>
        </Layout>
    )
}

export default Me;

const highlightText = {
    background: "linear-gradient(to top, #ffff00cb 50%, transparent 50%)"
}